<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ statistic }}
        </h2>
        <span>{{ statisticTitle }}</span>
      </div>
      <div v-if="badge">
        <b-avatar :variant="`light-${color}`" size="45" rounded="sm" :badge="badge" badge-top badge-offset="-0.5em" :badge-variant="color">
          <feather-icon size="21" :icon="icon" />
        </b-avatar>
      </div>
      <div v-else>
        <b-avatar :variant="`light-${color}`" size="45" rounded="sm" badge-top badge-offset="-0.5em" :badge-variant="color">
          <feather-icon size="21" :icon="icon" />
        </b-avatar>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
  import { BCard, BCardBody, BAvatar, BBadge } from 'bootstrap-vue';

  export default {
    components: {
      BCard,
      BCardBody,
      BAvatar,
      BBadge,
    },
    props: {
      badge: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        required: true,
      },
      statistic: {
        type: [Number, String],
        required: true,
      },
      statisticTitle: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: 'primary',
      },
    },
  };
</script>
