var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-card',{staticClass:"col-12 col-md-6",attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v("กลับ")])],1)]},proxy:true}])},[_c('b-card-text',[_c('validation-observer',{ref:"submitForm"},[_c('b-overlay',{attrs:{"show":_vm.playLoad,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm","no-wrap":""}}),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"แจ้งไปที่","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"แจ้งไปที่","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.reportInto,"state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.reportValuechang(_vm.formTicket.reportValue)}},model:{value:(_vm.formTicket.reportValue),callback:function ($$v) {_vm.$set(_vm.formTicket, "reportValue", $$v)},expression:"formTicket.reportValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"ประเภทเเจ้ง","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"ประเภทเเจ้ง","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":!_vm.formTicket.reportValue,"options":_vm.declaredType,"state":errors.length > 0 ? false : null},model:{value:(_vm.formTicket.declaredValue),callback:function ($$v) {_vm.$set(_vm.formTicket, "declaredValue", $$v)},expression:"formTicket.declaredValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"เรื่อง","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"เรื่อง","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"เรื่อง"},model:{value:(_vm.formTicket.title),callback:function ($$v) {_vm.$set(_vm.formTicket, "title", $$v)},expression:"formTicket.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"รายระเอียด","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"รายระเอียด","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"รายระเอียด","rows":"3"},model:{value:(_vm.formTicket.detall),callback:function ($$v) {_vm.$set(_vm.formTicket, "detall", $$v)},expression:"formTicket.detall"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"ลำดับความสำคัญ","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"ลำดับความสำคัญ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":[
                        { value: null, text: 'เลือกลำดับความสำคัญ' },
                        { value: 'Low', text: 'Low' },
                        { value: 'Medium', text: 'Medium' },
                        { value: 'High', text: 'High' } ],"state":errors.length > 0 ? false : null},model:{value:(_vm.formTicket.priority),callback:function ($$v) {_vm.$set(_vm.formTicket, "priority", $$v)},expression:"formTicket.priority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","label-cols-md":"4"}},[(_vm.formTicket.chatImage.length != 0)?_c('div',{staticClass:"row"},_vm._l((_vm.formTicket.chatImage),function(img,index){return _c('div',{key:index,staticClass:"col-4"},[_c('div',{staticClass:"text-center"},[_c('b-img',{attrs:{"width":"75","height":"50","src":_vm.imageConvertUrl(img),"variant":"light-primary"}}),_c('br'),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"flat-danger"},on:{"click":function () {
                            _vm.formTicket.chatImage.splice(index, 1);
                          }}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)])}),0):_vm._e()]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"รูปภาพ","label-cols-md":"4"}},[_c('b-form-file',{attrs:{"accept":"image/*","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","multiple":""},model:{value:(_vm.formTicket.chatImage),callback:function ($$v) {_vm.$set(_vm.formTicket, "chatImage", $$v)},expression:"formTicket.chatImage"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"เลือกตำเเหน่ง","label-cols-md":"4"}},[_vm._v(" "+_vm._s(_vm.formTicket.gps)+" ")]),_c('l-map',{staticStyle:{"width":"auto","height":"250px"},attrs:{"zoom":_vm.zoom,"center":_vm.center},on:{"update:zoom":function($event){_vm.zoom=$event},"update:center":function($event){_vm.center=$event}}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_c('l-marker',{attrs:{"latLng":_vm.formTicket.gps,"draggable":true},on:{"update:latLng":function($event){return _vm.$set(_vm.formTicket, "gps", $event)},"update:lat-lng":function($event){return _vm.$set(_vm.formTicket, "gps", $event)}}})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"offset-md":"4"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm()}}},[_vm._v(" บันทึก ")]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(" รีเซ็ต ")])],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }